<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'About',

    metaInfo: { title: 'About OlapDB' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'welcome',
        'why-choose-us',
        // 'powerful-features',
        'easy-to-use',
        'how-to-deploy',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
